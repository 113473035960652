// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\user\\Desktop\\PersonalSite\\PersonalSite\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\user\\Desktop\\PersonalSite\\PersonalSite\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("C:\\Users\\user\\Desktop\\PersonalSite\\PersonalSite\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-designing-for-machines-index-js": () => import("C:\\Users\\user\\Desktop\\PersonalSite\\PersonalSite\\src\\pages\\blog\\designing-for-machines\\index.js" /* webpackChunkName: "component---src-pages-blog-designing-for-machines-index-js" */),
  "component---src-pages-blog-hello-world-index-js": () => import("C:\\Users\\user\\Desktop\\PersonalSite\\PersonalSite\\src\\pages\\blog\\hello-world\\index.js" /* webpackChunkName: "component---src-pages-blog-hello-world-index-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\user\\Desktop\\PersonalSite\\PersonalSite\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-style-guide-js": () => import("C:\\Users\\user\\Desktop\\PersonalSite\\PersonalSite\\src\\pages\\style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */)
}

exports.data = () => import("C:\\Users\\user\\Desktop\\PersonalSite\\PersonalSite\\.cache\\data.json")

